<template>
    <Layout title="课程信息">    
        <template #extend>
            <el-button @click="handleReturn">返回上一页</el-button>
        </template>    

        <el-card class="custom-card__outer">
            <el-form :model="form" :rules="rules" ref="formRef" label-width="120px">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="课程名称" prop="name">
                            <el-input v-model="form.name" placeholder="请填写课程名称" />
                        </el-form-item>
                        <el-form-item label="学时" prop="classHour">
                            <el-input v-model="form.classHour" placeholder="请填写课程学时">
                                <template slot="append">分钟</template>
                            </el-input>
                        </el-form-item> 
                        <el-form-item label="价格" prop="price">
                            <el-input v-model="form.price" placeholder="请填写课程价格"> </el-input>
                        </el-form-item> 
                        <el-form-item label="课程封面">
                            <UploadImage @handleSuccess="handleUploadSuccess" :imgSrc="form.previewUrl" />
                        </el-form-item>                                                
                        <el-form-item label="举办单位" prop="organizer">
                            <el-select v-model="form.organizer">
                                <el-option
                                    v-for="item in options.organizerList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="培训类型" prop="trainingType">
                            <el-select v-model="form.trainingType">
                                <el-option
                                    v-for="item in options.trainingTypeList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="课程类型" prop="catId">
                            <el-select v-model="form.catId">
                                <el-option
                                    v-for="item in options.catList"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                        <el-form-item label="热门课程" prop="mobile">
                            <el-switch
                                v-model="form.isHot"
                                active-color="#13ce66"
                                inactive-color="#ff4949"
                                :active-value="1"
                                :inactive-value="0"
                            ></el-switch>
                        </el-form-item>
                        <el-form-item label="上课时间" prop="learningTime" v-if="form.trainingType === '1'">
                            <el-input v-model="form.learningTime" placeholder="请填写上课时间" />
                        </el-form-item>
                        <el-form-item label="上课地点" prop="form.learningAddress" v-if="form.trainingType === '1'">
                            <el-input v-model="form.learningAddress" placeholder="请填写上课地点" />
                        </el-form-item>
                        <el-form-item label="附件">
                            <el-upload
                                action
                                :file-list="form.attachment"
                                :before-upload="onBeforeFileUpload"
                                :http-request="onFileUpload"
                            >
                                <el-button size="small" type="primary">点击上传</el-button>
                                <div slot="tip" class="el-upload__tip">只能上传pdf/doc文件，且不超过10M</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="24">
                        <el-form-item label="课程介绍" prop="description">
                            <el-input type="textarea" v-model="form.description" rows="6" placeholder="请填写个人简介" />
                        </el-form-item> 
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <FixedActionBar>
            <el-button type="default" @click="handleCancel('formRef')">取消</el-button>
            <el-button type="primary" :loading="loading" :disabled="disabled" @click="handleConfirm('formRef')">确定</el-button>
        </FixedActionBar>
    </Layout>
</template>

<script>
import { 
    getCourseDetailApi,
    createCourseApi,
    getOptionsApi,
    editCourseApi,
    uploadFileApi,
    api_uploadFile
} from '@/api/course';

export default {
    data() {
        return {
            dialogVisible: true,

            courseId: null,

            options: {},

            form: {
                name: '',
                previewUrl: '',
                price: '',
                cover: '',
                catId: '',
                classHour: '',
                organizer: '',
                trainingType: '0',
                learningTime: '',
                learningAddress: '',
                isHot: '0',
                description: ''
            },

            trainingTypes: [],

            rules: {

            },

            loading: false,
            disabled: false
        };
    },

    created() {
        new Promise(resolve => {
            const { query } = this.$route;

            if (query.courseId) {
                this.courseId = Number(query.courseId);

                resolve();
            }
        }).then(() => {
            this.getData();             
        });

        this.getOptions();
    },

    methods: {
        // 获取课程详情数据 
        getData() {
            getCourseDetailApi({ courseId: this.courseId }).then(res => {
                if (res.data) {
                    const {
                        name,
                        cover,
                        price,
                        catId,
                        classHour,
                        organizer,
                        trainingType,
                        learningTime,
                        learningAddress,
                        isHot,
                        description,
                        previewUrl,
                        attachment
                    } = res.data;

                    this.form = {
                        name,
                        price,
                        cover,
                        catId: String(catId),
                        classHour: String(classHour),
                        organizer: String(organizer),
                        trainingType: String(trainingType),
                        learningTime,
                        learningAddress,
                        isHot,
                        description,
                        previewUrl,
                        attachment
                    };
                }
            });
        },

        // 获取培训类型
        getOptions() {
            getOptionsApi().then(res => {
                if (res.data) {
                    this.options = res.data;
                }
            });
        },

        handleReturn() {
            this.$router.go(-1);
        },

        handleUploadSuccess(data) {
            console.log('data: ', data);
            this.form.cover = data.BaseLookUrl;
            this.form.previewUrl = data.url;
        },

        handleHttpRequest(params) { 
            const formData = new FormData();
            formData.append('policy', params.file);

            uploadFileApi({
                body: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                console.log(res);
            });

        },

        // 文件上传之前
        onBeforeFileUpload(rawFile) {
            // console.log('rawFile: ', rawFile);
            const imgTypes = ['application/pdf', 'application/doc'];

            if (!imgTypes.includes(rawFile.type)) {
                // ElMessage.error('Avatar picture must be JPG format!')
                this.$message({
                    type: 'danger',
                    message: '请上传pdf,doc格式的文件'
                });

                return false;
            }
            return true;
        },

        // 自定义上传文件
        onFileUpload(params) {
            this.loadingIns = this.$loading({
                text: '文件上传中...',
                background: 'rgba(0, 0, 0, .2)'
            });

            api_uploadFile({ fileName: params.file.name }).then(res => {
                // 获取参数成功后，调用腾讯云接口
                const { UploadUrl, Host, Authorization, BaseLookUrl } = res.data;

                fetch(UploadUrl, {
                    method: 'PUT',
                    body: params.file,
                    headers: {
                        'Host': Host,
                        'Authorization': Authorization
                    }
                }).catch(res => {
                    return res.json();
                }).then(res => {
                    this.form.attachment = [{ name: params.file.name, url: BaseLookUrl }];
                    this.loadingIns.close();
                });
            }).catch(e => {
                this.loadingIns.close();
            });
        },

        // 取消
        handleCancel() {
            this.closeDialog();
        },

        request() {
            const { classHour, organizer, trainingType, catId, price } = this.form;
            const requestData = {
                ...this.form,
                classHour: Number(classHour),
                organizer: Number(organizer),
                trainingType: Number(trainingType),
                catId: Number(catId),
                price: Number(price)
            };

            if (this.courseId) { // 编辑
                requestData.courseId = this.courseId;
                editCourseApi(requestData).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功！'
                    });
                    this.$router.go(-1);
                });
            } else { // 添加
                createCourseApi(requestData).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功！'
                    });
                    this.$router.go(-1);
                });
            }
        },

        // 确定
        handleConfirm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.confirmLoading = true;
                    this.confirmDisabled = true;

                    this.request();

                    this.closeDialog();
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.uploader {    
    padding-top: 36px;
    ::v-deep.el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &:hover {
            border-color: #409EFF;
        }
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 178px;
        height: 178px;
        display: block;
    }
}
</style>
